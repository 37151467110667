/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { sortBy } from 'lodash';
import {
  sendEmailAdminTotalExportByEstab,
  sendEmailAdminTotalExportByGroup,
} from '../../services/mail';
import { calculeIndicateur } from '../../services/indicator';
import { generateInput } from '../../services/inputs';
import { getRewardContract } from '../../services/contracts';
import { getEstablishmentsList, getGroupsList } from '../../services/structures';
import Layout from '../../components/template/Layout';
import cn from '../../utils/cn';
import Card from '../../components/atoms/Card/Card';
import useAppContext from '../../store/useAppContext';
import styles from './TotalExport.module.css';
import Select from '../../components/atoms/Select/Select';
import Button from '../../components/atoms/Button/Button';

function TotalExport() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [context] = useAppContext();
  const [establishement, setEstablishement] = React.useState([]);
  const [group, setGroup] = React.useState([]);

  const navigateDashboards = () => {
    const etablissement = context?.choiceEstablishment?.name;
    if (context?.choiceBooklet === 1 && !context?.user?.role?.includes('ADMIN')) {
      navigate(`/sanitaire/${etablissement}/dashboards`);
    } else if (context.choiceBooklet === 2 && !context?.user?.role?.includes('ADMIN')) {
      navigate(`/securite/${etablissement}/dashboards`);
    } else if (context.choiceBooklet === 3 && !context?.user?.role?.includes('ADMIN')) {
      navigate(`/vehicule/${etablissement}/dashboards`);
    } else if (context?.user?.role?.includes('ADMIN')) {
      navigate('/dashboard');
    } else {
      navigate('/choix-carnet');
    }
  };

  const getEstablishmentsListQuery = useQuery(['establishment'], () => getEstablishmentsList());

  const getGroupsListQuery = useQuery(['group'], () => getGroupsList());
  const mapForSelect = (arrayToMap) => (arrayToMap?.length
    ? sortBy(arrayToMap, ['establishment']).map((item) => ({
      ...item,
      label: item.name,
      value: item.id,
    }))
    : []);

  const sendAdminDirectionEstab = useMutation(sendEmailAdminTotalExportByEstab, {
    onSuccess: () => {
      toast.success(t('cron.successEmail'));
    },
    onError: () => {
      toast.error(t('cron.errorEmail'));
    },
  });

  const sendAdminDirectionGroup = useMutation(sendEmailAdminTotalExportByGroup, {
    onSuccess: () => {
      toast.success(t('cron.successEmail'));
    },
    onError: () => {
      toast.error(t('cron.errorEmail'));
    },
  });

  const onChangeEstablishement = (e) => {
    setEstablishement(e.value);
  };

  const onChangeGroup = (e) => {
    setGroup(e.value);
  };

  const sendMail = (choice) => {
    if (choice === 1) {
      sendAdminDirectionEstab.mutate(establishement);
    } else {
      sendAdminDirectionGroup.mutate(group);
    }
  };

  return (
    <Layout
      title="Export Total"
      description="Export Total des carnets"
      keywords="Export Total"
    >
      <button type="button" className="link" onClick={() => navigateDashboards()}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <span>{t('global.dashboard')}</span>
      </button>
      <br />
      <br />
      <Card>
        <div className={styles.title}>
          <h1 className="title no-margin">{t('cron.total_export')}</h1>
        </div>
      </Card>
      <div className={styles.card}>
        <div className={styles.emailsUser}>
          <Select
            required
            label={t('cron.etablissement')}
            name="etablissement"
            options={
              mapForSelect(getEstablishmentsListQuery?.data?.data?.establishments)
            }
            className={styles.establishement}
            onChange={(e) => onChangeEstablishement(e)}
            isLoading={getEstablishmentsListQuery.isLoading}
          />
          <Button
            label={t('cron.admin_export')}
            onClick={() => sendMail(1)}
            className={styles.admin_sanitaire}
            isLoading={sendAdminDirectionEstab.isLoading}
          />
        </div>
        <div className={styles.admin}>
          <Select
            required
            label={t('cron.group')}
            name="group"
            options={
              mapForSelect(getGroupsListQuery?.data?.data?.groups)
            }
            className={styles.group}
            onChange={(e) => onChangeGroup(e)}
            isLoading={getGroupsListQuery.isLoading}
          />
          <Button
            label={t('cron.admin_export')}
            onClick={() => sendMail(2)}
            className={styles.admin_sanitaire}
            isLoading={sendAdminDirectionGroup.isLoading}
          />
        </div>
      </div>
    </Layout>
  );
}

export default TotalExport;
